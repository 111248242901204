import React from "react";
import Layout from "../components/Layout";
import Speak from "../components/Speak";

const ContestRules = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="px-6 m-auto py-28 max-w-7xl md:px-8">
        <h1 className="md:text-4xl text-center text-2xl uppercase montserrat text-[#454646] font-bold    mb-16 ">
          WIN A WEEK IN THE FLORIDA KEYS SWEEPSAKES RULES
        </h1>
        <p className="mb-10 text-lg font-normal uppercase lg:text-2xl">
          ‍NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN A PRIZE.  A PURCHASE
          OR PAYMENT WILL NOT INCREASE YOUR CHANCES OF WINNING.  VOID WHERE
          PROHIBITED BY LAW.
        </p>
        <p className="mb-16 text-lg font-normal uppercase lg:text-2xl">
          BY ENTERING INTO OR PARTICIPATING IN THE SWEEPSTAKES, YOU AGREE TO BE
          BOUND BY THESE OFFICIAL RULES, WHICH CONSTITUTE A CONTRACT, SO READ
          THEM CAREFULLY, IF YOU DO NOT AGREE WITH THE TERMS OF THIS CONTRACT,
          DO NOT ENTER THE SWEEPSTAKES.
        </p>
        <ol>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Sponsorship.  The WIN A WEEK IN THE FLORIDA KEYS (the
              “Sweepstakes”) is sponsored by DealerSys, LLC (“Sponsor”) with a
              principal address of 2416 Amsler Rd Torrance, CA 90505 (“Sponsor’s
              Location”).  The Sweepstakes is governed by these Official Rules.
               By participating in the Sweepstakes, you agree to be bound by
              these Official Rules and the decisions of the Sponsor (including
              Sponsor’s interpretation and application of these Official Rules),
              which are final and binding.  Winning a prize is contingent upon
              fulfilling all requirements set forth in these Official Rules in a
              timely manner.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Eligibility.  The Sweepstakes is open to all natural persons who
              (a) are residents of the United States; (b) at the time of entry,
              are the age of majority in your state of residency; (c) have a
              valid e-mail address (d) set a technology discussion or demo
              appointment with DealerSys, LLC during the Digital Dealer Las
              Vegas 2022 conference (October 8-10, 2022), and subsequently
              attend the appointment on or before November 23, 2022.  As
              applicable, owners of the Sponsor, employees of the Sponsor, their
              affiliates, licensees, franchisees, advertising and promotional
              agencies and the immediate family members of each are not
              eligible. An “immediate family member” is defined to mean spouse,
              parent, grandparent, child or sibling. In-laws are not considered
              to be immediate family members.  Entries submitted by or on behalf
              of entrants who do not meet the eligibility requirements are void.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Sweepstakes Procedure.  During the Entry Period (as herein
              defined), Participants may enter by visiting this page, signing up
              for a technology discussion or demo appointment with DealerSys,
              LLC, and subsequently attending the appointment on or before
              November 23, 2022. Participants must submit (1) name, (2) employer
              or affiliated company name, if applicable, (3) and valid email
              address. Each entrant will receive one (1) entry into the
              promotion during the Entry Period.  Entries that are incomplete,
              illegible, damaged, destroyed, late, misdirected, deceptive or
              otherwise not in compliance with the Official Rules may be
              disqualified from any and all Sweepstakes, at the Sponsor’s sole
              and absolute discretion.  Attempts made by the same individual to
              submit multiple entries by using multiple emails, addresses,
              accounts or other means may be disqualified.  Entries generated by
              any automated means are void and may be disqualified.
            </p>
          </li>
        </ol>
        <p className="mb-10 text-lg font-light lg:text-2xl">
          The Sweepstakes will begin on Tuesday, October 8, 2022 at 8:00AM ET
          and end on Thursday, October 10, 2021 at 11:59PM ET, unless earlier
          terminated or canceled by Sponsor (the “Entry Period”).
        </p>
        <ol>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-6 text-lg font-normal uppercase lg:text-2xl">
              NO PURCHASE IS NECESSARY TO ENTER OR WIN THE SWEEPSTAKES.
               FURTHER, A URCHASE WILL NOT IMPROVE A PARTICIPANT’S CHANCES OF
              WINNING A PRIZE IN THE SWEEPSTAKES.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              ‍Drawings.  The random drawing(s) will be conducted by a
              representative of Sponsor, or at Sponsor’s direction, from all
              eligible entries received during the Entry Period (each, a
              “Drawing”).  There will be one winner if less than 20 total
              appointments are set during the entry period and attended from
              October 11 thru November 22, 2022. If more than 20 appointments
              are set during the entry period and attended before November 23,
              2022, then a second prize of equal value will be rewarded. The
              potential winner(s) will be selected at random from all eligible
              entries received during the Entry Period, who subsequently
              attended a DealerSys technology appointment in from October 11
              thru November 22, 2022. The first and potentially second winner
              selected on or about Wednesday November 23, 2022.‍
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Announcement of Winners.  If you are a potential winner in a
              Drawing, you will be notified by the Sponsor, within forty-eight
              (48) hours following the Drawing, via e-mail at the e-mail address
              associated with the potential winner’s entry into the Sweepstakes.
               To claim a prize, you must reply to the Sponsor within
              forty-eight (48) hours of such notification, or as otherwise
              directed in the notification. If the potential winner for a
              Drawing does not respond to the Sponsor’s prize notification email
              within forty-eight (48) hours or if the notification is rejected,
              faulty, unclaimed or returned undeliverable (for any reason
              whatsoever) to such potential winner, the potential winner may be
              deemed to have forfeited the prize, and the Sponsor will have the
              sole and absolute discretion to select, by random drawing, another
              potential winner from the remaining eligible entries.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Prizes, Prize Values, Odds of Winning and Collecting Prizes.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              By entering this promotion, Participant is eligible to win
              a week-long (7 day stay, 6 night) accomodation on Sugarloaf Key,
              FL in a 3 bed 2 bath home with 2 domestic plane tickets (economy
              or economy plus up to a $1,000 total value). The dates for
              redemption are March 7, 2023 – September 7, 2023. Dates are
              subject to availability and the discretion of Sponsor. The A prize
              may not be transferred and must be accepted as awarded.  A winner
              may not request cash or a substitute prize; however, Sponsor
              reserves the right to substitute a prize with another prize of
              equal of greater value if the prize is not available for any
              reason, as determined by Sponsor in its sole discretion.   It is
              the Participant’s responsibility to read and understand all
              information, if any, which accompanies any prize concerning use,
              risks, and maintenance.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              All costs and expenses associated with a Participant’s acceptance
              and use of a prize not specified herein as being provided as part
              of the prize are the sole responsibility of the winning
              Participant.  A winning Participant is solely responsible for
              reporting and paying all applicable federal, state and local taxes
              related to the prize.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              The odds of winning are dependent entirely on the number of
              entries received during the applicable Entry Period for the
              Drawing.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              The retail value of each prize is Three Thousand Five Hundred
              Dollars ($3,500.00)
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Winner will be required to verify eligibility.  The prize
              information will be sent to the winner via email.  There are no
              shipping or handling fees associated with collecting the prize.
               Limit one prize per person.‍
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Release. To the fullest extent permitted by law, Participants
              forever release, hold harmless, discharge, and covenant not to sue
              Sponsor, affiliates, owners, employees, agents, directors,
              officers, shareholders, contractors, successors and assigns
              (collectively “Released Parties”) for, from and against any and
              all claims, debts, liabilities, demands, obligations, costs, fees,
              expenses, actions, causes of action whatsoever, of every nature,
              character and description (collectively, “Claims”), known and
              unknown, arising out of or in any way related to the Sweepstakes,
              these Official Rules, the negligence of any of the Released
              Parties, entry into the promotion and the acceptance, possession,
              misuse or use of any prize. in addition, each Participant
              acknowledges and agrees that the Released Parties are not
              responsible for any costs, injuries, losses, or damages of any
              kind arising from or in connection with: (i) late, lost, delayed,
              damaged, misdirected, incomplete, illegible, unintelligible or
              misdirected entries; (ii) any condition caused by events beyond
              the control of the Sponsor that may cause the promotion to be
              disrupted or discontinued; (iii) any failure to receive or process
              entries due to any cause, including without limitation, human,
              transmission or technical problems, failures, or malfunctions of
              any kind, howsoever originating, that may limit an participant’s
              ability to participate in the sweepstakes including any
              interruptions to any network, server, Internet, website,
              telephone, satellite, computer or other connections; (iv) garbled,
              misdirected or jumbled transitions, or traffic congestion; (v) any
              injuries, losses, or damages of any kind caused by a prize or
              resulting from acceptance, possession, or use of a prize, or from
              entry into or participation in the promotion (including, without
              limitation, claims, costs, injuries, losses and damages related to
              personal injuries, death, damage to, loss or destruction of
              property, or any claims, costs, injuries, losses, or damages
              related to or based on the participant’s rights of publicity or
              privacy, or the participant’s claim that they have somehow been
              defamed or portrayed in a false light); (vi) any printing or
              typographical errors in any materials associated with the
              promotion, or (vii) late, lost, delayed, or damaged delivery of
              the prize.
            </p>
          </li>
        </ol>
        <p className="mb-10 text-lg font-light lg:text-2xl">
          To the fullest extent permitted by law, under no circumstances will a
          winner be permitted to obtain awards for, and each Participant and
          winner hereby waives all rights to claim, indirect, punitive,
          incidental and consequential damages and any other damages arising out
          of or related in any way to the Sweepstakes, these Official Rules,
          Participant’s entry, the negligence of any of the Released Parties,
          and the acceptance, possession, misuse or use of any prize.  Some
          jurisdictions do not allow the limitation or exclusion of liability
          for incidental or consequential damages, so the above may not apply to
          each Participant.
        </p>
        <ol>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Use of Disclosed Information.  By entering the Sweepstakes or
              claiming a Prize, Participant and/or winner may be sharing their
              personal information with Sponsor.  Information transmitted to
              Sponsor will generally be treated as non-confidential and
              non-proprietary.  By entering the Sweepstakes, you consent to the
              Sponsor sending to your e-mail address promotional content
              including, but not limited to, information concerning the
              technology products and services of its technology partners. If
              you do not wish to receive these e-mails, you may “opt out” by
              following the instructions on an e-mail that you receive.  If you
              “opt out” of receiving e-mails from the Sponsor, it will not
              affect your eligibility to participate in the Sweepstakes. Please
              note, however, that if you “opt-out” of receiving e-mails, the
              Sponsor may still contact you via e-mail concerning the
              Sweepstakes including notifying you if you are a potential winner
              of a prize.  By entering the Sweepstakes or claiming a prize you
              consent to Sponsor’s use of each winner’s name, biographical
              information and/or likeness for online posting and/or any other
              written or photographic advertising and publicity without
              additional compensation.  ‍
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Reservations. Sponsor reserves the right, in its sole discretion,
              to cancel, modify or suspend the Sweepstakes at any time for any
              reason in its sole discretion, which shall be final. All
              Participants agree to abide by and be bound by these Official
              Rules. Sponsor reserves the right to permanently disqualify and/or
              revoke a prize from any person it believes has violated these
              Official Rules or has acted in a manner that is, in Sponsor’s
              discretion, offensive, harassing, threatening, repugnant, or
              violent at any time, including any tampering with the entry
              process. Participants further agree to not knowingly damage or
              cause interruption of the Sweepstakes and/or prevent others from
              participating in the Sweepstakes. All decisions of Sponsor shall
              be final and entrants waive any right to challenge any decisions
              made by Sponsor.‍ ‍
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Governing Law/Venue.  All issues and questions concerning the
              construction, validity, interpretation and enforceability of these
              Official Rules or the rights and obligations of the entrants or
              Sponsor in connection with the Sweepstakes shall be governed by
              and construed in accordance with the laws of the state of Nevada
              without giving effect to any choice of law or conflict of law
              rules or provisions that would cause the application of any other
              state’s laws, and any matters or proceedings which are not subject
              to arbitration as set forth in these Official Rules and/or for
              entering any judgment on an arbitration award, shall take place in
              courts located in the State of Ohio.
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Social Media Disclosure.  Although this promotion may be
              advertised on the LinkedIn, Facebook pages, Twitter accounts,
              Instagram accounts or other social media platforms of Sponsor or
              another, this promotion is in no way sponsored, endorsed or
              administered by, or associated with, LinkedIn, Facebook, Twitter,
              Instagram or other social media platforms. Participants are
              providing their information to the Sponsor and not to LinkedIn,
              Facebook, Twitter, Instagram or other social media platforms. The
              information a Participant provides will only be used by the
              Sponsor. By entering, Participants provide a complete release of
              LinkedIn, Facebook, Twitter or Instagram from any and all
              responsibilities implied or provided by the Sponsor and that this
              promotion is in no way sponsored, endorsed or administered by, or
              associated with, LinkedIn, Facebook, Twitter or Instagram.‍
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Binding Arbitration. Except with respect to the protection and
              enforcement of the intellectual property rights of the Sponsor and
              its rights to seek and/or obtain injunctive or equitable relief
              therefore, any claim, cause of action or proceeding arising out of
              or relating to these Official Rules or the Sweepstakes shall be
              resolved by mandatory, binding arbitration in Chicago, Illinois
              administered by the American Arbitration Association under its
              Commercial Arbitration Rules (the “Arbitration Rules”). The U.S.
              Arbitration Act shall govern the interpretation, enforcement and
              proceedings pursuant to the Arbitration Rules.  The arbitrator
              shall be selected pursuant to the Arbitration Rules. If any part
              of this arbitration provision is deemed to be invalid,
              unenforceable or illegal (other than that claims will not be
              arbitrated on a class or representative basis), or otherwise
              conflicts with the Arbitration Rules and procedures, then the
              balance of this arbitration provision shall remain in effect and
              shall be construed in accordance with its terms as if the invalid,
              unenforceable, illegal or conflicting provision were not contained
              herein.  If, however, the portion that is deemed invalid,
              unenforceable or illegal is that claims will not be arbitrated on
              a class or representative basis, then the entirety of this
              arbitration provision shall be null and void, and neither claimant
              nor Sponsor shall be entitled to arbitrate their dispute. Upon
              filing a demand for arbitration, all parties to such arbitration
              shall have the right of discovery, which discovery shall be
              completed within sixty (60) days after the demand for arbitration
              is made, unless further extended by mutual agreement of the
              parties.
            </p>
          </li>
        </ol>
        <p className="mb-6 text-lg font-normal uppercase lg:text-2xl">
          THE ARBITRATION OF DISPUTES PURSUANT TO THIS PARAGRAPH SHALL BE IN THE
          PARTICIPANT’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. THE
          ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR
          PARTIES WHO MAY BE SIMILARLY SITUATED. DO NOT ENTER THIS SWEEPSTAKES
          IF YOU DO NOT AGREE TO HAVE ANY CLAIM OR CONTROVERSY ARBITRATED IN
          ACCORDANCE WITH THESE OFFICIAL RULES AND THE ARBITRATION RULES.
        </p>
        <p className="mb-6 text-lg font-normal uppercase lg:text-2xl">
          BY PARTICIPATING IN THE SWEEPSTAKES, EACH PARTICIPANT AGREES THAT TO
          THE EXTENT PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES,
          CLAIMS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE
          SWEEPSTAKES, OR ANY PRIZE AWARDED, WILL BE RESOLVED INDIVIDUALLY
          THROUGH BINDING ARBITRATION AS SET FORTH ABOVE, WITHOUT RESORT TO ANY
          FORM OF CLASS ACTION; (2) ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS
          WILL BE LIMITED TO ACTUAL THIRD- PARTY, OUT-OF-POCKET COSTS INCURRED
          (IF ANY), BUT IN NO EVENT WILL ATTORNEYS’ FEES BE AWARDED OR
          RECOVERABLE; (3) UNDER NO CIRCUMSTANCES WILL ANY PARTICIPANT BE
          PERMITTED TO OBTAIN ANY AWARD FOR, AND PARTICIPANT HEREBY KNOWINGLY
          AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL,
          CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER
          DAMAGES, OTHER THAN ACTUAL OUT OF POCKET EXPENSES, AND/OR ANY RIGHTS
          TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED; AND (4)
          PARTICIPANT’S REMEDIES ARE LIMITED TO A CLAIM FOR MONEY DAMAGES (IF
          ANY) AND PARTICIPANT IRREVOCABLY WAIVES ANY RIGHT TO SEEK INJUNCTIVE
          OR EQUITABLE RELIEF. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS
          OR EXCLUSION OF LIABILITY, SO THE ABOVE MAY NOT APPLY TO EVERY
          PARTICIPANT.
        </p>
        <ol>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Winner’s List. To obtain a copy of these Official Rules or any
              legally required winner’s list, send a self-addressed stamped
              envelope (except residents of VT may omit return postage) to
              Sponsor at Sponsor’s Location.  A request for a winner’s list must
              be received within 30 days of the end of the Entry Period.‍
            </p>
          </li>
          <li className="text-lg font-light list-decimal ml-14 lg:text-2xl">
            <p className="mb-10 text-lg font-light lg:text-2xl">
              Severability. If any provision(s) of these Official Rules is held
              to be invalid or unenforceable, all remaining provisions hereof
              will remain in full force and effect.  Sponsor reserves the right
              in its sole discretion to supplement or make changes to these
              Official Rules without notice.
            </p>
          </li>
        </ol>
        <p className="mb-10 text-lg font-light lg:text-2xl">
          DealerSys, LLC is in no way related to or associated with Digital
          Dealer and its affiliates.
        </p>
      </div>
      <Speak location={location} />
    </Layout>
  );
};

export default ContestRules;
